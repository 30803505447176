import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* TODO Move this to proper body section... */}
        <p>New website under construction</p>
        <img src="/botFace.png" className="App-botface" alt="botFace" />
        <p>
          Rolling Out Soon...
        </p>
      </header>
    </div>
  );
}

export default App;